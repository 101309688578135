import authService from "src/services/auth.service";
import { clientIdMappper } from "src/constants/domain-constant";
import { userService } from "src/services";

export class GuardsService {
  private allowedPath = [
    "/canvas",
    "/fmx",
    "/",
    "/v2/canvas",
    "/v2/fmx",
    "/v2/ortho",
  ];

  isAuthorized(): boolean {
    const params = new URLSearchParams(window.location.search);
    const { pathname } = window.location;
    const clientId = params.get("clientId");
    // Guardian Flow
    if (
      this.allowedPath.includes(pathname) &&
      clientId &&
      clientIdMappper[clientId]
    )
      return true;
    // * NOT_ENOUGH.
    // * Should be more checks and conditions to know if user is valid.
    // check authentication
    if (!authService.getAuth()?.accessToken) {
      window.newrelic.log("Requesting user is not authenticated", {
        level: "error",
        path: pathname,
      });
      return false;
    }

    // check if user profile loaded successfully
    if (!this.isUserProfileLoaded()) {
      window.newrelic.log("User profile not loaded", {
        level: "error",
        path: pathname,
      });
      return false;
    }

    return true;
  }

  isUserProfileLoaded(): boolean {
    const userProfile = userService.getUserProfile();
    return !!userProfile?.email || !!userProfile?.username;
  }
}

const guardsService = new GuardsService();
export default guardsService;
